import { createSelector } from 'reselect';

export const jobsSelector = ({ jobs }) => jobs.jobs;

export const jobsDataSelector = ({ jobs }) => jobs;

export const pollingEnabledJobsSelector = ({ jobs }) => jobs.pollingEnabledJobs;

const jobIdsSelector = ({ jobs }) => jobs.jobIds;

export const updateFilingMemberJobIdSelector = createSelector(
  jobIdsSelector,
  jobIds => jobIds.updateFilingMemberJobId,
);
