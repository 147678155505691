import { useEffect } from 'react';
import { WebPubSubClient } from '@azure/web-pubsub-client';
import { useDispatch, useSelector } from 'react-redux';

import {
  setConnectionId,
  connectionIdSelector,
  setServerJobDataSelector,
  ServerJobData,
} from '../store/webSocketReducer';
import { useQueryGetWebPubClientUrl } from '../queries/azure';
import { errorNotification } from '../../shared/notification/store/actions';

const WEB_SOCKET_CONNECTION_FAILED = '(WSCE#002)';

const useWebsocketClient = () => {
  const dispatch = useDispatch();

  const connectionId = useSelector(connectionIdSelector);

  const clientAccessUrl = useQueryGetWebPubClientUrl({
    staleTime: Infinity,
  });

  useEffect(() => {
    if (connectionId) {
      return;
    }

    if (!clientAccessUrl) {
      return;
    }

    const clientHub = new WebPubSubClient(clientAccessUrl);

    try {
      clientHub.start();

      clientHub.on('connected', data => {
        if (data.connectionId) {
          dispatch(setConnectionId(data.connectionId));
        }
      });

      clientHub.on('server-message', data => {
        const serverMessage = (data.message.data as unknown) as ServerJobData;
        dispatch(setServerJobDataSelector(serverMessage));
      });
    } catch (error) {
      const errorMessage = `Something went wrong. Please try again later. ${WEB_SOCKET_CONNECTION_FAILED}`;

      dispatch(errorNotification(errorMessage));
    }
  }, [clientAccessUrl]);
};

export default useWebsocketClient;
