import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@pwc/appkit-react';
import { taxFormsV2Schemas } from '@common-packages/validators';
import { Formik } from 'formik';
import omit from 'lodash.omit';
import { Routes } from '@common-packages/routes-definitions';

import { HeaderWithParamDropdownsWrapper } from '../../shared/displayComponents/headerWithParamDropdowns';
import { errorNotification } from '../../shared/notification/store/actions';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import Loading from '../../shared/displayComponents/loading.component';
import SplitPane from '../../shared/displayComponents/splitPane/splitPane.component';
import { TabsTypes } from '../expressionBuilder/functions/constants';
import {
  fetchPDFFieldsByPage,
  fetchMappingFormPDFPageDataUsageAndAttachmentConfigs,
  fetchOverflowActionTypes,
  selectPDFPageDataUsageAndAttachmentConfig,
  resetPDFFieldsByPage,
  resetMappingFormPDFPageDataUsageAndAttachmentConfigs,
} from '../../shared/store/dataModels/actions';
import { FIRST_PAGE } from '../dataModels/constants';
import {
  pdfFieldsByPageOptionsSelector,
  isFetchingPDFFieldsByPageSelector,
  mappingFormPDFPageDataUsageAndAttachmentConfigsSelector,
  mappingFormPDFPageDataUsageAndAttachmentConfigIdSelector,
  pdfFieldSelector,
} from '../../shared/store/dataModels/selectors';
import {
  taxYearSelector,
  jurisdictionIdSelector,
  formPageSelector,
  formIdSelector,
  formCanBeFoundInListSelector,
  formPdfIdSelector,
} from '../store/selectors';
import { fetchForms, fetchFormHtmlImage, resetFormHtmlImage } from '../store/actions';
import AppkitIcon from '../../shared/displayComponents/appkitIcon/appkitIcon.component';
import useOpenInNewTab from '../../shared/hooks/useOpenInNewTab';
import WarningModal from '../../shared/displayComponents/warningModal.component';
import useModal from '../../shared/hooks/useModal.hook';
import { setActiveTab as setActiveExpressionBuilderTab } from '../expressionBuilder/store/actions';
import { useUpdateDevelopmentContextFromQueryParams } from '../store/hooks';
import { jobByIdSelector } from '../../shared/store/webSocketReducer';
import { JobStatus } from '../../shared/enums/job';

import {
  fetchTaxForm,
  updateTaxForm,
  fetchDataModelsWithGroupedDatasetsAndDataItems,
  fetchPdfPageDataUsage,
  resetPdfPageDataUsage,
} from './store/actions';
import {
  groupedDatasetsSelector,
  groupedDataItemsSelector,
  pdfPageDataUsageSelector,
  dataModelsOptionsSelector,
  isFetchingDataModelsWithGroupedDatasetsAndDataItemsSelector,
} from './store/selectors';
import {
  assignedDatasetsTreeSelector,
  assignedDatasetsSelector,
  availableDatasetsTreeSelector,
  dataModelIdSelector,
  getDatasetsToAssign,
  getDatasetsToUnassign,
  isFormDirtySelector,
  isSavingSelector,
  selectedFieldIdSelector,
  selectedFieldNameSelector,
  selectedFormDetailsTabSelector,
  taxFormsReducer,
  taxFormsReducerInitialState,
  selectedAvailableDatasetsSelector,
  selectedAssignedDatasetsSelector,
  datasetsParentsSelector,
} from './utils/taxForms.reducer';
import getNamesWhereAttachmentChanged from './utils/getNamesWhereAttachmentChanged';
import Form from './form/form.container';
import FormDetails from './formDetails/formDetails.component';
import {
  TaxFormActionTypes,
  FIELDS_TO_UPDATE,
  FIELDS_TO_UNMAP,
  WARNING_MODAL_REFRESH_MESSAGE,
  WARNING_MODAL_REFRESH_TITLE,
} from './constants';
import getAvailableDatasetsForPage from './utils/getAvailableDatasetsForPage';
import mapFieldsToUpdate from './utils/mapFieldsToUpdate';
import styles from './styles.module.scss';

const TaxForm = () => {
  const dispatch = useDispatch();

  useUpdateDevelopmentContextFromQueryParams();

  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);

  const formId = useSelector(formIdSelector);
  const formCanBeFoundInList = useSelector(formCanBeFoundInListSelector);
  const dataModelsOptions = useSelector(dataModelsOptionsSelector);
  const datasets = useSelector(groupedDatasetsSelector);
  const dataItems = useSelector(groupedDataItemsSelector);

  const pdfPageDataUsage = useSelector(pdfPageDataUsageSelector);
  const pdfId = useSelector(formPdfIdSelector) || null;
  const selectedPage = useSelector(formPageSelector);
  const pdfFieldsByPageOptions = useSelector(pdfFieldsByPageOptionsSelector);
  const isFetchingPDFFieldsByPage = useSelector(isFetchingPDFFieldsByPageSelector);
  const isFetchingDataModelsWithGroupedDatasetsAndDataItems = useSelector(
    isFetchingDataModelsWithGroupedDatasetsAndDataItemsSelector,
  );

  const mappingFormPDFPageDataUsageAndAttachmentConfigs = useSelector(
    mappingFormPDFPageDataUsageAndAttachmentConfigsSelector,
  );
  const selectedDatasetId = useSelector(mappingFormPDFPageDataUsageAndAttachmentConfigIdSelector);

  const [state, taxFormsDispatch] = useReducer(taxFormsReducer, taxFormsReducerInitialState);

  const isFormDirty = isFormDirtySelector(state);
  const isSaving = isSavingSelector(state);
  const selectedFormFieldId = selectedFieldIdSelector(state);
  const selectedFormFieldName = selectedFieldNameSelector(state);
  const availableDatasetsTree = availableDatasetsTreeSelector(state);
  const assignedDatasetsTree = assignedDatasetsTreeSelector(state);
  const assignedDatasets = assignedDatasetsSelector(state);
  const dataModelId = dataModelIdSelector(state);
  const datasetsAssignedToFormPage = getAvailableDatasetsForPage(pdfPageDataUsage, selectedPage);
  const selectedFormDetailsTab = selectedFormDetailsTabSelector(state);
  const selectedAvailableDatasets = selectedAvailableDatasetsSelector(state);
  const selectedAssignedDatasets = selectedAssignedDatasetsSelector(state);
  const datasetsParents = datasetsParentsSelector(state);
  const selectedFormField = useSelector(state => pdfFieldSelector(state, selectedFormFieldId));

  const jobById = useSelector(state => jobByIdSelector(state, { jobId: formId }));

  // TODO: Testing, remove after
  console.log(jobById);

  const isPdfProcessed =
    !jobById || (jobById && jobById.jobId === formId && jobById.status === JobStatus.SUCCESS);

  // TODO: Testing, remove after
  console.log(isPdfProcessed);

  const rootFormRef = useRef();
  const isContextReady = taxYear && jurisdictionId;

  const { showModal: showWarningModal, modalProps } = useModal();

  const setIsFormDirty = useCallback(isFormDirty => {
    taxFormsDispatch({
      type: TaxFormActionTypes.SET_IS_FORM_DIRTY,
      payload: isFormDirty,
    });
  }, []);

  const setIsSaving = useCallback(isSaving => {
    taxFormsDispatch({
      type: TaxFormActionTypes.SET_IS_SAVING,
      payload: isSaving,
    });
  }, []);

  const handleSelectedAvailableDatasets = useCallback(selectedAvailableDatasets => {
    taxFormsDispatch({
      type: TaxFormActionTypes.SELECT_AVAILABLE_DATASETS,
      payload: selectedAvailableDatasets,
    });
  }, []);

  const handleSelectedAssignedDatasets = useCallback(selectedAssignedDatasets => {
    taxFormsDispatch({
      type: TaxFormActionTypes.SELECT_ASSIGNED_DATASETS,
      payload: selectedAssignedDatasets,
    });
  }, []);

  const handleFormFieldChange = useCallback((fieldId, fieldName) => {
    taxFormsDispatch({
      type: TaxFormActionTypes.SELECT_FORM_FIELD_ID,
      payload: fieldId,
    });

    taxFormsDispatch({
      type: TaxFormActionTypes.SELECT_FORM_FIELD_NAME,
      payload: fieldName,
    });

    // eslint-disable-next-line no-unused-expressions
    document.getElementById(fieldName)?.scrollIntoView({ block: 'nearest' });
    // Using block: 'nearest' because with the default (block: 'start') if the user selected
    // a field at the bottom of the page, the current page would change to the next one.
    // Also, not using behavior: 'smooth' because it doesn't work on Chrome if we change the
    // field on the right pane.
  }, []);

  const handleDatasetUnassign = useCallback(() => {
    taxFormsDispatch({
      type: TaxFormActionTypes.UNASSIGN_DATASETS,
      payload: { datasets: selectedAssignedDatasets },
    });

    // Delete unassigned datasets from overflowActions in formik context
    const overflowActions = rootFormRef?.current?.values.overflowActions;

    if (rootFormRef.current && overflowActions) {
      const newValues = omit(
        overflowActions,
        selectedAssignedDatasets.map(({ id }) => id),
      );

      if (Object.keys(newValues).length) {
        rootFormRef.current.setValues(previousValues => ({
          ...previousValues,
          overflowActions: newValues,
        }));
      } else {
        rootFormRef.current.setValues(previousValues => omit(previousValues, 'overflowActions'));
      }
    }
  }, [selectedAssignedDatasets]);

  const handleDatasetAssign = useCallback(
    pageNumber => {
      taxFormsDispatch({
        type: TaxFormActionTypes.ASSIGN_DATASETS,
        payload: { datasets: selectedAvailableDatasets, pageNumber, parents: datasetsParents },
      });
    },
    [selectedAvailableDatasets, datasetsParents],
  );

  const selectDataModelId = useCallback(({ value }) => {
    taxFormsDispatch({
      type: TaxFormActionTypes.SELECT_DATA_MODEL_ID,
      payload: { value },
    });
  }, []);

  const selectFormDetailTab = useCallback(tab => {
    taxFormsDispatch({
      type: TaxFormActionTypes.SELECT_FORM_DETAIL_TAB,
      payload: tab,
    });
  }, []);

  useEffect(() => {
    if (!formCanBeFoundInList && isContextReady) {
      dispatch(fetchForms({ taxYear, jurisdictionId }));
    }
  }, [dispatch, isContextReady, formCanBeFoundInList, taxYear, jurisdictionId]);

  useEffect(() => {
    if (taxYear && jurisdictionId) {
      taxFormsDispatch({
        type: TaxFormActionTypes.RESET,
      });

      dispatch(fetchForms({ taxYear, jurisdictionId }));
      dispatch(fetchDataModelsWithGroupedDatasetsAndDataItems({ taxYear, jurisdictionId }));
    }
  }, [dispatch, taxYear, jurisdictionId]);

  useEffect(() => {
    if (pdfId && isPdfProcessed) {
      dispatch(fetchPdfPageDataUsage({ pdfId }));
      dispatch(fetchMappingFormPDFPageDataUsageAndAttachmentConfigs({ pdfId }));
    } else if (!pdfId) {
      dispatch(resetPdfPageDataUsage());
      dispatch(resetMappingFormPDFPageDataUsageAndAttachmentConfigs());
    }
  }, [dispatch, pdfId, isPdfProcessed]);

  useEffect(() => {
    dispatch(fetchOverflowActionTypes());
  }, [dispatch]);

  useEffect(() => {
    if (formId) {
      rootFormRef.current.resetForm();
      dispatch(fetchTaxForm({ formId }));
    }
  }, [dispatch, formId]);

  useEffect(() => {
    if (formId && pdfId && isPdfProcessed) {
      dispatch(fetchFormHtmlImage({ formId, pdfId }));
    } else if (formId && !pdfId) {
      dispatch(resetFormHtmlImage());
    }
  }, [dispatch, formId, pdfId, isPdfProcessed]);

  useEffect(() => {
    if (pdfId && selectedPage && isPdfProcessed) {
      dispatch(fetchPDFFieldsByPage({ pdfId, pageNumber: selectedPage }));
    } else if (!pdfId || !selectedPage) {
      dispatch(resetPDFFieldsByPage());
    }
  }, [dispatch, pdfId, selectedPage, isPdfProcessed]);

  useEffect(() => {
    const isPreviouslySelectedFieldIdAvailable = pdfFieldsByPageOptions.some(
      ({ value }) => value === selectedFormFieldId,
    );

    if (!isPreviouslySelectedFieldIdAvailable && !isFetchingPDFFieldsByPage) {
      const newFieldId = pdfFieldsByPageOptions[0]?.value;
      handleFormFieldChange(newFieldId);
    }
  }, [
    handleFormFieldChange,
    isFetchingPDFFieldsByPage,
    pdfFieldsByPageOptions,
    selectedFormFieldId,
  ]);

  const getNewWindowParams = useCallback(() => {
    const { id } = selectedFormField;

    const updatedFormField = rootFormRef.current?.values.fieldsToUpdate?.[id];
    if (!updatedFormField) {
      const { dataItemId, dataModelId, datasetId } = selectedFormField;

      return {
        dataItemId,
        dataModelId,
        datasetId,
      };
    }

    const { dataItemId, dataModelId, datasetId } = updatedFormField;

    return {
      dataItemId,
      dataModelId,
      datasetId,
    };
  }, [selectedFormField]);

  const softResetState = useCallback(() => {
    taxFormsDispatch({
      type: TaxFormActionTypes.SOFT_RESET,
      payload: {
        datasets,
        pdfPageDataUsage,
        dataModelsOptions,
        dataModelId: dataModelsOptions[0]?.value || null,
      },
    });
  }, [dataModelsOptions, datasets, pdfPageDataUsage]);

  useEffect(() => {
    softResetState();
  }, [softResetState]);

  const saveValues = useCallback(async () => {
    setIsSaving(true);
    const taxFormValidationResult = await rootFormRef.current.validateForm();
    const isTaxFormValidationError = Boolean(Object.keys(taxFormValidationResult || {}).length);
    if (isTaxFormValidationError) {
      const notificationErrorMessage = taxFormValidationResult.formDetails.swpCalcIteration
        ? 'SWP Calc Iteration is needed'
        : 'Validation errors';
      dispatch(errorNotification(notificationErrorMessage));
      setIsSaving(false);
      return;
    }

    const formValues = rootFormRef.current.values;

    const datasetsToAssign = getDatasetsToAssign(pdfPageDataUsage, state.pdfPageDataUsage);
    const datasetsToUnassign = getDatasetsToUnassign(pdfPageDataUsage, state.pdfPageDataUsage);
    const fieldsToUpdate =
      formValues?.[FIELDS_TO_UPDATE] &&
      mapFieldsToUpdate({
        newFields: formValues[FIELDS_TO_UPDATE],
        fieldsToUnmap: formValues[FIELDS_TO_UNMAP],
        newPdfPageDataUsage: state.pdfPageDataUsage,
        dataItems,
      });
    const fieldsToUnmap = formValues?.[FIELDS_TO_UNMAP]?.map(
      ({ pdfFieldMappingId }) => pdfFieldMappingId,
    );

    await dispatch(
      updateTaxForm({
        formId,
        formPdfId: pdfId,
        datasetsToAssign,
        datasetsToUnassign,
        ...formValues,
        [FIELDS_TO_UPDATE]: fieldsToUpdate,
        [FIELDS_TO_UNMAP]: fieldsToUnmap,
      }),
    );

    rootFormRef.current.resetForm();
    softResetState();
    setIsFormDirty(false);
    setIsSaving(false);

    await Promise.allSettled([
      dispatch(fetchTaxForm({ formId })),
      dispatch(fetchPdfPageDataUsage({ pdfId })),
      dispatch(fetchMappingFormPDFPageDataUsageAndAttachmentConfigs({ pdfId })),
    ]);

    await dispatch(fetchPDFFieldsByPage({ pdfId, pageNumber: selectedPage }));
    // preserve previously selected dataset
    dispatch(selectPDFPageDataUsageAndAttachmentConfig(selectedDatasetId));
  }, [
    dispatch,
    setIsFormDirty,
    softResetState,
    setIsSaving,
    formId,
    pdfId,
    selectedPage,
    pdfPageDataUsage,
    dataItems,
    state.pdfPageDataUsage,
    selectedDatasetId,
  ]);

  const handleSaveButton = useCallback(() => {
    if (!rootFormRef.current) {
      return;
    }

    const names = getNamesWhereAttachmentChanged(
      mappingFormPDFPageDataUsageAndAttachmentConfigs,
      rootFormRef.current.values?.overflowActions,
    );
    if (names.length) {
      dispatch(
        showConfirmModal({
          title: 'Warning',
          text: `Overflow action is no longer Attachment for: ${names.join(
            ', ',
          )}. All attachment columns will be removed.`,
          confirmText: 'OK',
          confirmCallback: saveValues,
        }),
      );
    } else {
      saveValues();
    }
  }, [dispatch, saveValues, mappingFormPDFPageDataUsageAndAttachmentConfigs]);

  const handleCancel = useCallback(() => {
    if (rootFormRef.current) {
      rootFormRef.current.resetForm();
      softResetState();
      dispatch(selectPDFPageDataUsageAndAttachmentConfig(FIRST_PAGE));
      setIsFormDirty(false);
    }
  }, [softResetState, setIsFormDirty, dispatch]);

  const isDataDirty = Boolean(
    getDatasetsToAssign(pdfPageDataUsage, state.pdfPageDataUsage).length ||
      getDatasetsToUnassign(pdfPageDataUsage, state.pdfPageDataUsage).length ||
      isFormDirty,
  );

  const openInNewTab = useOpenInNewTab();

  const handleExpressionBuilderButtonClick = useCallback(() => {
    const { dataItemId, dataModelId, datasetId } = getNewWindowParams();

    dispatch(setActiveExpressionBuilderTab(TabsTypes.FORM));

    openInNewTab(Routes.expressionBuilder.MAIN, {
      taxYear,
      jurisdictionId,
      formId,
      dataModelId,
      datasetId,
      dataItemId,
    });
  }, [dispatch, getNewWindowParams, openInNewTab, taxYear, jurisdictionId, formId]);

  const handleDataModelsButtonClick = useCallback(() => {
    const { dataItemId, dataModelId, datasetId } = getNewWindowParams();

    openInNewTab(Routes.developmentDataModels.MAIN, {
      taxYear,
      jurisdictionId,
      dataModelId,
      datasetId,
      dataItemId,
    });
  }, [getNewWindowParams, openInNewTab, taxYear, jurisdictionId]);

  const handleRefreshButtonClick = useCallback(() => {
    if (isDataDirty) {
      return showWarningModal();
    }

    if (taxYear && jurisdictionId) {
      dispatch(fetchDataModelsWithGroupedDatasetsAndDataItems({ taxYear, jurisdictionId }));
    }

    if (pdfId && isPdfProcessed) {
      dispatch(fetchPdfPageDataUsage({ pdfId }));
    }
  }, [dispatch, showWarningModal, isDataDirty, taxYear, jurisdictionId, pdfId, isPdfProcessed]);

  const isRefreshingDataModels =
    isFetchingPDFFieldsByPage || isFetchingDataModelsWithGroupedDatasetsAndDataItems;
  const disableRefreshButton = isSaving || isRefreshingDataModels;
  const disableSaveButtons = !isPdfProcessed || !isDataDirty || isSaving || isRefreshingDataModels;

  return (
    <>
      <div className="row">
        <div className="col-8">
          <HeaderWithParamDropdownsWrapper
            showHeaderTaxYearDropdown
            showHeaderJurisdictionDropdown
            showHeaderTaxFormDropdown
          />
        </div>
        <div className="col-3">
          <Button size="sm" kind="secondary" onClick={handleExpressionBuilderButtonClick}>
            Expressions ...
          </Button>
          <Button size="sm" kind="secondary" onClick={handleDataModelsButtonClick}>
            Data Models ...
          </Button>
          <button
            title="Refresh data"
            onClick={handleRefreshButtonClick}
            disabled={disableRefreshButton}
            className={styles.refreshButton}
          >
            {isRefreshingDataModels ? (
              <Loading small isLoading />
            ) : (
              <AppkitIcon icon="refresh" size={16} />
            )}
          </button>
        </div>
        <div className={`col-1 ${styles.taxFormSaveCancelButtons}`}>
          <Button
            size="sm"
            kind="secondary"
            onClick={handleSaveButton}
            disabled={disableSaveButtons}
          >
            <div className={styles.saveButton}>
              Save
              <Loading small isLoading={isSaving} />
            </div>
          </Button>
          <Button size="sm" kind="secondary" onClick={handleCancel} disabled={disableSaveButtons}>
            Cancel
          </Button>
        </div>
      </div>
      <Formik
        innerRef={rootFormRef}
        initialValues={{}}
        validationSchema={taxFormsV2Schemas.taxFormUpdateSchema}
        enableReinitialize
      >
        <SplitPane>
          <Form
            selectedFormFieldId={selectedFormFieldId}
            selectedFormFieldName={selectedFormFieldName}
            handleFormFieldChange={handleFormFieldChange}
            selectFormDetailTab={selectFormDetailTab}
            setIsFormDirty={setIsFormDirty}
          />
          <FormDetails
            formId={formId}
            setIsFormDirty={setIsFormDirty}
            selectedFormFieldId={selectedFormFieldId}
            handleFormFieldChange={handleFormFieldChange}
            datasetsAssignedToFormPage={datasetsAssignedToFormPage}
            availableDatasetsTree={availableDatasetsTree}
            assignedDatasets={assignedDatasets}
            assignedDatasetsTree={assignedDatasetsTree}
            handleDatasetUnassign={handleDatasetUnassign}
            handleDatasetAssign={handleDatasetAssign}
            dataModelId={dataModelId}
            selectDataModelId={selectDataModelId}
            selectedFormDetailsTab={selectedFormDetailsTab}
            selectFormDetailTab={selectFormDetailTab}
            pdfPageDataUsage={state.pdfPageDataUsage}
            selectedAvailableDatasets={selectedAvailableDatasets}
            selectedAssignedDatasets={selectedAssignedDatasets}
            handleSelectedAvailableDatasets={handleSelectedAvailableDatasets}
            handleSelectedAssignedDatasets={handleSelectedAssignedDatasets}
          />
        </SplitPane>
      </Formik>
      <WarningModal
        title={WARNING_MODAL_REFRESH_TITLE}
        warningMessage={WARNING_MODAL_REFRESH_MESSAGE}
        {...modalProps}
      />
    </>
  );
};

export default TaxForm;
