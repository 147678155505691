import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { successNotification, errorNotification } from '../../shared/notification/store/actions';
import { JobStatus } from '../../shared/enums/job';
import { jobByIdSelector } from '../../shared/store/webSocketReducer';

import { updateFilingMemberJobIdSelector } from '../../jobs/store/selectors';
import { clearUpdateFilingMembersJobId } from '../../jobs/store/actions';

const NOTIFICATION_DURATION = 5000;

export const useUpdateFilingMembersJobNotification = () => {
  const dispatch = useDispatch();

  const latestUpdateFilingMembersJobId = useSelector(updateFilingMemberJobIdSelector);
  const jobData = useSelector(
    state => jobByIdSelector(state, { jobId: latestUpdateFilingMembersJobId }), //
  );

  useEffect(() => {
    const jobStatus = latestUpdateFilingMembersJobId && jobData?.status;

    if (jobStatus === JobStatus.SUCCESS) {
      dispatch(
        successNotification('Filing members updated in tax service successfully', {
          duration: NOTIFICATION_DURATION,
        }),
      );
      dispatch(clearUpdateFilingMembersJobId());
    } else if (jobStatus === JobStatus.FAILED) {
      dispatch(
        errorNotification('Filing members update in tax service failed', {
          duration: NOTIFICATION_DURATION,
        }),
      );
      dispatch(clearUpdateFilingMembersJobId());
    }
  }, [dispatch, jobData, latestUpdateFilingMembersJobId]);
};
