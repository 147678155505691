import {
  defaultColumnDefinitionWithFilter,
  adminColumnDefinition,
  selectCellEditorParamsFactory,
} from '../../shared/columnDefinitions';
import PropTypes from 'prop-types';

import { SELECT_OPTIONS } from '../../shared/displayComponents/constants';
import config from '../../config';
import {
  EditableCheckboxCellRenderer,
  AdminCellRendererFactory,
  TextCellRendererFactory,
} from '../../shared/columnDefinitions/cellRenderers';
import { dateFormatter } from '../../shared/formatters';
import {
  defaultDateCellEditor,
  defaultAgRichSelectCellEditorSelector,
} from '../../shared/columnDefinitions/cellEditor';
import { SelectOption, dataTypes } from '../../shared/constants';

import styles from './usersMaintenance.module.scss';

const TextRenderer = TextCellRendererFactory({
  getText: ({ value }) => (value ? value : ''),
});

const RendererFramework = props => {
  if (props.data.dataType === dataTypes.CHECKBOX) {
    return <EditableCheckboxCellRenderer {...props} />;
  }
  if (!props.data.expireDate && !props.data.value) {
    return <div className={styles.redTextWrapper}>Missing Expiration Date</div>;
  }
  return <TextRenderer {...props} />;
};

RendererFramework.propTypes = {
  data: PropTypes.shape({
    dataType: PropTypes.string,
    expireDate: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

const EditorSelector = props => {
  switch (props.data.dataType) {
    case dataTypes.DATE:
      return {
        ...defaultDateCellEditor,
        params: {
          value: props.data.value,
        },
      };
    case dataTypes.NUMBER:
      return {
        component: 'NumberCellEditor',
        params: {
          value: props.data.value,
        },
      };
    default:
      return;
  }
};

const getColumnDefinitions = ({ onEditIconClick, onDeleteIconClick, isInEditMode, updateRow }) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    onDeleteIconClick,
    onEditIconClick,
    shouldShowDeleteIcon: Boolean(onDeleteIconClick),
  });

  const onCellValueChanged = ({ data }) => updateRow(data);

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Role',
      field: 'roleName',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'First Name',
      field: 'firstName',
      width: 140,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Last Name',
      field: 'lastName',
      width: 140,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Email Address',
      field: 'email',
      width: 140,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Created By',
      field: 'createdBy',
      width: 140,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Created',
      field: 'created',
      width: 140,
      valueFormatter: ({ value }) => dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT),
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Updated By',
      field: 'updatedBy',
      width: 140,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Updated',
      field: 'updated',
      width: 140,
      valueFormatter: ({ value }) => dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT),
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Last Login',
      field: 'lastLogin',
      width: 140,
      valueFormatter: ({ value }) => dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT),
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Expires',
      field: 'expireDate',
      width: 140,
      valueFormatter: ({ value }) => dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT),
      editable: isInEditMode,
      onCellValueChanged,
      cellRenderer: RendererFramework,
      cellEditorSelector: props => {
        return {
          ...defaultDateCellEditor,
          params: {
            value: props.data.expireDate,
          },
        };
      },
      cellRendererParams: {
        disabled: !isInEditMode,
      },
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Active',
      field: 'activeInd',
      width: 60,
      editable: isInEditMode,
      onCellValueChanged,
      cellStyle: {
        textAlign: 'center',
      },
      cellEditorSelector: props => {
        return {
          ...defaultAgRichSelectCellEditorSelector,
          params: selectCellEditorParamsFactory(SELECT_OPTIONS)(),
        };
      },
      cellRenderer: EditableCheckboxCellRenderer,
      cellRendererParams: {
        disabled: !isInEditMode,
      },
    },
  ];

  return columnDefinitions;
};
export default getColumnDefinitions;
