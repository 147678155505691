import { AliasTypes, FilingMethods, FilingMethodsIndex } from './enums';

export const columnSizes = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const columnTypes = {
  LINK: 'link',
  INPUT: 'input',
};

export const columnFormats = {
  NONE: 'none',
  DATE: 'date',
  FEDERAL_ID: 'federal id',
  PERCENTAGE_6: '(0.000000%)',
};

export const alignments = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
};

export const fieldFormats = {
  NONE: 'none',
  DATE: 'date',
  FEDERAL_ID: 'federal id',
  PERCENTAGE_6: '(0.000000%)',
};

export const gridSectionHeights = {
  CUSTOM: 'custom',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const dataTypes = {
  CHECKBOX_GROUP: 'CHECKBOX_GROUP',
  CHECKBOX: 'CHECKBOX',
  DATE: 'DATE',
  NUMBER: 'NUMBER',
  TEXT: 'TEXT',
  AMOUNT: 'AMOUNT',
};

export const DataItemsTypes = {
  CURRENCY: 'Currency',
  RATIO: 'Ratio',
  INTEGER: 'Integer',
  DATE: 'Date',
  BOOLEAN: 'Boolean',
  STRING: 'String',
};

export const filingTypes = {
  SEPARATE: 'SEPARATE',
  CONSOLIDATED: 'CONSOLIDATED',
};

export const filingTypesOptions = [
  {
    label: 'Separate',
    value: filingTypes.SEPARATE,
  },
  {
    label: 'Consolidated Pre, Post and Unitary',
    value: filingTypes.CONSOLIDATED,
  },
];

export const CONNECTICUT_JURISDICTION_ID = '0900000000';
export const EVERYWHERE_JURISDICTION_ID = 'EV00000000';
export const ALL_JURISDICTION_ID = 'all';
export const TAX_YEAR_ENDING_INFO_ACCOUNT_ID = 'PDEND';

export const EVERYWHERE_JURISDICTION_DISPLAYORDER = 0;
export const EVERYWHERE_JURISDICTION_DESCRIPTION = 'EVERYWHERE';
export const EVERYWHERE_JURISDICTION_STATECODE = 'EV';

export const FORM_WRAPPER_CLASS = 'pdf-form-content';
export const NO_PDF_FORM_MESSAGE = 'There is no PDF file available for this form';

export const PRINT_SERVICE_PAGE_CONTAINER_ID = 'page-container';

export const EverywhereJurisdictionDropdownOption = {
  label: EVERYWHERE_JURISDICTION_DESCRIPTION,
  value: EVERYWHERE_JURISDICTION_ID,
};

export const PropagateDataModelEverywhereJurisdiction = {
  displayOrder: EVERYWHERE_JURISDICTION_DISPLAYORDER,
  jurisdictionDescription: EVERYWHERE_JURISDICTION_DESCRIPTION,
  jurisdictionId: EVERYWHERE_JURISDICTION_ID,
  stateCode: EVERYWHERE_JURISDICTION_STATECODE,
  usedInConsol: false,
};

export const AllJurisdictionDropdownOption = {
  label: 'ALL',
  value: ALL_JURISDICTION_ID,
};

// Match field FormStatus
export const FORM_STATUS_IN_DEVELOPMENT = '2';

// Boolean select options
// Update the common package SelectOption after refactoring the remaining related screens
export interface SelectOption {
  value: boolean | null;
  label: string;
}
export declare type SelectOptionsResponse = SelectOption[];
export interface FilingMethodOption {
  value: FilingMethods;
  label: string;
  numberValue: number;
  iconTitle?: string;
  disabled?: boolean;
}

const EFILE_OPTION = {
  label: 'E-File',
  value: FilingMethods.EFILE,
  numberValue: FilingMethodsIndex.EFILE + 1,
};
const PAPER_OPTION = {
  label: 'Paper File',
  value: FilingMethods.PAPER,
  numberValue: FilingMethodsIndex.PAPER + 1,
};
const NON_FILING_OPTION = {
  label: 'Non-Filing',
  value: FilingMethods.NON_FILING,
  numberValue: FilingMethodsIndex.NON_FILING + 1,
};
const DELETE_OPTION = { label: 'Delete', value: null };

export const FILING_METHOD_EDIT_OPTIONS: FilingMethodOption[] = [EFILE_OPTION, PAPER_OPTION];

export const FILING_METHOD_OPTIONS: FilingMethodOption[] = [
  ...FILING_METHOD_EDIT_OPTIONS,
  NON_FILING_OPTION,
];

export const FILING_METHOD_OPTIONS_WITHOUT_EFILE = [PAPER_OPTION, NON_FILING_OPTION];

export const updateReturnFilingMethodOptions: { label: string; value: string | null }[] = [
  ...FILING_METHOD_EDIT_OPTIONS.map(({ label, numberValue }) => ({
    label,
    value: `${numberValue}`,
  })),
  DELETE_OPTION,
];

export const updateReturnFilingMethodOptionsWithoutEfile: {
  label: string;
  value: string | null;
}[] = updateReturnFilingMethodOptions.filter(
  ({ value }) => value !== `${EFILE_OPTION.numberValue}`,
);

export interface AliasTypeOption {
  label: string;
  value: AliasTypes;
}

export const ALIAS_TYPE_OPTIONS: AliasTypeOption[] = [
  { label: 'Account', value: AliasTypes.Account },
  { label: 'Info Account', value: AliasTypes.InfoAccount },
  { label: 'Category', value: AliasTypes.Category },
];

export const NotApplicableContextValue = 'NA';

export const ROOT_NAVIGATOR_TAB = {
  NAVIGATOR: 'navigator',
  BINDER: 'binder',
  RETURNS: 'returns',
};

export const RefreshStatus = {
  NotStarted: 0,
  InProgress: 1,
  Complete: 2,
  Failed: 3,
  Cancelled: 4,
  SourceMissingData: 5,
};
