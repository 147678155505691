export enum Jobs {
  GENERATE_TAX_FORM_PDF = 'generate-tax-form-pdf',
  GENERIC_ACCOUNT_DATA_UPLOAD = 'generic-account-data-upload',
  PROPAGATE_DATA_MODEL = 'propagate-data-model',
  CLEAN_UP_RETURNS_IN_ALL_BRANCHES = 'clean-up-returns-in-all-branches',
  OWNERSHIP_ALLOCATIONS_UPLOAD = 'ownership-allocations-upload',
  UPDATE_EFILE_FILING_MEMBERS = 'update-efile-filing-members',
}

export enum JobStatus {
  NA,
  IN_PROGRESS,
  SUCCESS,
  FAILED,
}
