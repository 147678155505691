import { Jobs } from '../../../../shared/enums/job';

interface Job {
  id: string;
  type: Jobs;
}

interface BaseJobMessage {
  connectionId: string;
  job: Job;
}

interface File {
  file: Blob;
  fileName: string;
}

type StartJobVariable = BaseJobMessage & {
  stringifiedJsonData: string;
  files?: File[];
};

const appendJobFormData = ({ connectionId, stringifiedJsonData, job, files }: StartJobVariable) => {
  const body = new FormData();
  body.append('connectionId', connectionId);
  body.append('jobId', job.id);
  body.append('jobType', job.type);
  body.append('stringifiedJsonData', stringifiedJsonData);

  files?.forEach(file => {
    if (file) {
      body.append(file.fileName, file.file);
    }
  });

  return body;
};

export default appendJobFormData;
