import { QueryError, QueryKeys } from '../queries';
import { httpPostFileAndParse } from '../../utils/fetchService';
import { JobMessage } from '../../../../common/types';

import appendJobFormData from './utils/job/appendJobFormData';

import { useCustomMutation } from '.';

export type StartJobVariable = JobMessage;

interface JobStartResponse {
  jobId: string;
  message: JobMessage;
}

export const useMutationStartJob = () => {
  return useCustomMutation<JobStartResponse, QueryError, StartJobVariable, string>({
    resetKey: QueryKeys.Job.Start,
    customMutate: ({ connectionId, stringifiedJsonData, job, files }: StartJobVariable) =>
      httpPostFileAndParse({
        route: '/api/job/start',
        body: appendJobFormData({ connectionId, stringifiedJsonData, job, files }),
      }),
    successMessage: 'Started job successfully',
    errorMessage: 'Failed to start job',
  });
};
