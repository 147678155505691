import { createSlice } from '@reduxjs/toolkit';

import { Jobs, JobStatus } from '../../shared/enums/job';

type ConnectionId = string | null;

interface ConnectionIdPayload {
  payload: ConnectionId;
}

export type ServerJobData = {
  jobId: string;
  jobType: Jobs;
  status: JobStatus;
  progress?: number;
  startTime: string;
  endTime: string;
  result?: unknown;
  error: string | null;
} | null;

interface ServerJobDataPayload {
  payload: ServerJobData;
}

interface WebSocketState {
  connectionId: ConnectionId;
  serverJobsData: ServerJobData[];
}

const initialState: WebSocketState = {
  connectionId: null,
  serverJobsData: [],
};

// want to make sure this aligns with 'client/src/store.js'
const name = 'webSocket';

const webSocketSlice = createSlice({
  name,
  initialState,
  reducers: {
    setConnectionId(state, { payload: connectionId }: ConnectionIdPayload) {
      state.connectionId = connectionId;
    },
    setServerJobDataSelector(state, { payload: serverJobData }: ServerJobDataPayload) {
      const existingJobIndex = state.serverJobsData.findIndex(
        job => job?.jobId === serverJobData?.jobId,
      );

      if (existingJobIndex !== -1) {
        // Update existing job
        state.serverJobsData[existingJobIndex] = serverJobData;
      } else {
        // Add new job
        state.serverJobsData.push(serverJobData);
      }
    },
  },
});

const { actions, reducer: webSocketReducer } = webSocketSlice;

export const { setConnectionId, setServerJobDataSelector } = actions;

export const connectionIdSelector = (state: { [key: string]: WebSocketState }) =>
  state[name].connectionId;

export const serverJobDataSelector = (state: { [key: string]: WebSocketState }) =>
  state[name].serverJobsData;

export const jobByIdSelector = (
  state: { [key: string]: WebSocketState },
  { jobId }: { jobId: string },
) => {
  return state[name].serverJobsData.find(job => `${job?.jobId}` === `${jobId}`) || null;
};

export default webSocketReducer;
