import { NegotiateWPSResponse } from '../../../../common/types';

import { QueryKeys, useCustomQuery } from './index';

export const useQueryGetWebPubClientUrl = ({ staleTime }: { staleTime: number }) =>
  useCustomQuery<NegotiateWPSResponse>([QueryKeys.Azure.Shared.NegotiateWPS], {
    url: '/api/azure/shared/negotiate',
    staleTime,
    errorMessage: 'Error creating connection. (WSCE#001)',
  }).data?.url;
