import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import {
  globalContextSelector,
  isGlobalContextReadySelector,
} from '../../../../shared/store/selectors';
import {
  useQueryGetReturnValidationResults,
  useQueryGetSubmissionFiles,
  useQueryGetTransmissionStatus,
  useQueryGetValidationErrors,
  useQueryGetSubmissionsHistory,
} from '../../../../shared/queries/eFileV2';
import { useBinder } from '../../hooks';
import { DENIED_STATUS } from '../../constants';
import {
  Link,
  SubmissionId,
  BinderFilingId,
  GetTransmissionStatusResponse,
  TaxReturnResult,
} from '../../../../../../common/types';
import { FederalReturnOption, TransmitterProvider } from '../../../../shared/enums/eFileV2';
import { generateFederalFileUrl, generateStateFileUrl } from '../../utils/generateFileUrls';
import { StepStatus, TransmissionStatus } from '../../enums';
import stringify from '../../../../utils/stringify';
import { QueryKeys } from '../../../../shared/queries';

export interface SubmissionListItem {
  id: string;
  date: Date;
}

interface TransmitStepContext {
  isLoading: boolean;
  submissionsList: SubmissionListItem[];
  businessEntityId: string;
  isSeparate: boolean;
  selectedTransmissionStatusData: GetTransmissionStatusResponse | null;
  federalSubmissions: Link[];
  stateSubmissions: Link[];
  warnings: number;
  errors: number;
  transmissionZipLink: Link | null;
  transmissionAcknowledgementLink: Link | null;
  isFetchingSubmissions: boolean;
  isFetchingTransmissionStatus: boolean;
  isFetchingSubmissionFiles: boolean;
}

interface UseTransmitStepContextParams {
  binderFilingId?: BinderFilingId;
  step5Status: StepStatus | null;
  isCheckingTransmissionStatus: boolean;
  federalSubmissionId: SubmissionId | null;
  federalReturnOption: FederalReturnOption | null;
  setTransmissionStatus: (status: TransmissionStatus) => void;
  setSelectedTransmissionHistoryId: (id: string) => void;
  isReadyToCheckTransmissionStatus: boolean;
  isCheckTransmissionStatusNotRunPreviously: boolean;
  checkTransmissionStatus: () => void;
  ttiSubmissionId: string | null;
  isFetchingTaxReturnOrTransmitterProviderTR: boolean;
  taxReturn: TaxReturnResult | undefined;
  selectedTransmissionHistoryId: string;
}

const DOWNLOAD_TRANSMISSION_FILE_STEP_NAME = 'download-transmission-file';
const DOWNLOAD_TRANSMISSION_FILE_ACK_STEP_NAME = 'download-transmission-file-acknowledgement';

const useViewStatusStepContext = ({
  binderFilingId,
  step5Status,
  isCheckingTransmissionStatus,
  federalSubmissionId,
  federalReturnOption,
  setTransmissionStatus,
  isReadyToCheckTransmissionStatus,
  isCheckTransmissionStatusNotRunPreviously,
  checkTransmissionStatus,
  isFetchingTaxReturnOrTransmitterProviderTR,
  taxReturn,
  selectedTransmissionHistoryId,
  setSelectedTransmissionHistoryId,
}: UseTransmitStepContextParams): TransmitStepContext => {
  const globalContext = useSelector(globalContextSelector);
  const isGlobalContextReady = useSelector(isGlobalContextReadySelector);
  const {
    taxYear,
    period,
    jurisdictionId,
    isSeparateYN,
    entityId,
    isSeparate,
    params: { businessEntityId },
  } = globalContext;

  const { returnId } = useParams<{ returnId?: string }>();

  const { ttiYearId, ttiBinderId, ttiCaseData, sltBinderId } = useBinder(
    !isFetchingTaxReturnOrTransmitterProviderTR,
  );

  // Check transmission status when first time accessing step 5
  useEffect(() => {
    if (isCheckTransmissionStatusNotRunPreviously && isReadyToCheckTransmissionStatus) {
      checkTransmissionStatus();
    }
  }, [
    isCheckTransmissionStatusNotRunPreviously,
    isReadyToCheckTransmissionStatus,
    checkTransmissionStatus,
  ]);

  const {
    data: ttiValidationErrors,
    isFetching: isFetchingTtiValidationErrors,
  } = useQueryGetValidationErrors({
    params: {
      taxYear,
      period,
      jurisdictionId,
      isSeparateYN,
      businessEntityId: entityId,
      sltBinderId,
    },
    enabled: isGlobalContextReady,
  });

  const {
    data: eFileSchemaValidationErrors,
    isFetching: isFetchingEFileSchemaValidationErrors,
  } = useQueryGetReturnValidationResults({
    params: { sltBinderId },
    enabled: isGlobalContextReady && Boolean(sltBinderId),
  });

  const areTransmissionStatusParamsReady = useMemo(
    () =>
      taxReturn?.selectedTransmitter === TransmitterProvider.TR ||
      Boolean((ttiCaseData?.ttiCaseId && ttiYearId && ttiBinderId) || taxReturn?.isTTIReadOnly),
    [ttiCaseData, ttiBinderId, ttiYearId, taxReturn],
  );

  const queryClient = useQueryClient();
  const {
    data: submissions,
    isFetching: isFetchingSubmissions,
    isFetched: isSubmissionsHistoryFetched,
  } = useQueryGetSubmissionsHistory({
    params: {
      binderFilingId,
      returnId: parseInt(returnId || '0', 10),
    },
    enabled:
      Boolean(binderFilingId && returnId) &&
      // we need to wait for current transmission to return at least one status
      !isCheckTransmissionStatusNotRunPreviously,
    onSuccess: transmissionData => {
      setSelectedTransmissionHistoryId(transmissionData[0].id);
      queryClient.resetQueries([QueryKeys.EFileSupport.EFileBinderFilingsData]);
    },
  });

  const isGetTransmissionStatusQueryEnabled =
    areTransmissionStatusParamsReady &&
    !isCheckingTransmissionStatus &&
    step5Status === StepStatus.COMPLETED &&
    Boolean(binderFilingId) &&
    isGlobalContextReady &&
    Boolean(sltBinderId) &&
    isSubmissionsHistoryFetched &&
    Boolean(selectedTransmissionHistoryId);

  const {
    data: transmissionStatusData,
    isFetched: isTransmissionStatusFetched,
    isFetching: isFetchingTransmissionStatus,
  } = useQueryGetTransmissionStatus({
    params: {
      binderFilingId: String(binderFilingId),
      globalContextApiRouteParamString: globalContext.apiRouteParamString,
      sltBinderId: String(sltBinderId),
      transmissionHistoryId: selectedTransmissionHistoryId,
      isSeparateYN,
    },
    enabled: Boolean(isGetTransmissionStatusQueryEnabled),
  });

  const {
    data: submissionFiles,
    isFetching: isFetchingSubmissionFiles,
  } = useQueryGetSubmissionFiles({
    params: {
      globalContext,
      stateSubmissionId: transmissionStatusData?.stateSubmissionId ?? '',
      federalSubmissionId,
      federalReturnOption,
      returnId: sltBinderId,
      binderFilingId: binderFilingId || null,
    },
    enabled:
      isGlobalContextReady &&
      Boolean(selectedTransmissionHistoryId && transmissionStatusData?.stateSubmissionId),
  });

  useEffect(() => {
    if (isTransmissionStatusFetched && transmissionStatusData) {
      setTransmissionStatus(transmissionStatusData.status);
    }
  }, [isTransmissionStatusFetched, transmissionStatusData, setTransmissionStatus]);

  const transmissionZipLink = useMemo(
    () =>
      transmissionStatusData && !(taxReturn?.selectedTransmitter === TransmitterProvider.TR)
        ? {
            label: 'E-File Transmission ZIP',
            url: `/api/efile-v2/files/step-file/${selectedTransmissionHistoryId}/${binderFilingId}?${stringify(
              {
                stepName: DOWNLOAD_TRANSMISSION_FILE_STEP_NAME,
              },
            )}`,
          }
        : null,
    [transmissionStatusData, selectedTransmissionHistoryId, binderFilingId],
  );

  const transmissionAcknowledgementLink = useMemo(
    () =>
      transmissionStatusData && transmissionStatusData.status !== TransmissionStatus[DENIED_STATUS]
        ? {
            label: 'E-File Transmission Acknowledgement',
            url: `/api/efile-v2/files/step-file/${selectedTransmissionHistoryId}/${binderFilingId}?${stringify(
              {
                stepName: DOWNLOAD_TRANSMISSION_FILE_ACK_STEP_NAME,
              },
            )}`,
          }
        : null,
    [transmissionStatusData, selectedTransmissionHistoryId, binderFilingId],
  );

  return {
    isLoading:
      !isFetchingTaxReturnOrTransmitterProviderTR &&
      (!isGlobalContextReady ||
        !areTransmissionStatusParamsReady ||
        (isCheckTransmissionStatusNotRunPreviously
          ? !isReadyToCheckTransmissionStatus || isCheckingTransmissionStatus
          : false) ||
        isFetchingTransmissionStatus ||
        isFetchingSubmissionFiles ||
        isFetchingTtiValidationErrors ||
        isFetchingEFileSchemaValidationErrors ||
        isFetchingSubmissions ||
        (isGetTransmissionStatusQueryEnabled && !isTransmissionStatusFetched)),
    submissionsList: submissions || [],
    selectedTransmissionStatusData: transmissionStatusData || null,
    federalSubmissions:
      submissionFiles && federalReturnOption
        ? submissionFiles.federalFiles.map(
            ({ fileName, id: proformaImportId, path: filePath }) => ({
              label: fileName,
              url: generateFederalFileUrl({
                proformaImportId,
                filePath,
                federalReturnOption,
                taxYear: globalContext.params.taxYear,
              }),
            }),
          )
        : [],
    stateSubmissions: submissionFiles
      ? submissionFiles.stateFiles.map(({ fileName, id, path, fileLocator }) => ({
          label: fileName,
          url: generateStateFileUrl({
            id,
            path,
          }),
          fileLocator,
        }))
      : [],
    isSeparate,
    businessEntityId,
    errors: (eFileSchemaValidationErrors?.errors || 0) + (ttiValidationErrors?.errors || 0),
    warnings: (eFileSchemaValidationErrors?.warnings || 0) + (ttiValidationErrors?.warnings || 0),
    transmissionZipLink,
    transmissionAcknowledgementLink,
    isFetchingSubmissions,
    isFetchingTransmissionStatus,
    isFetchingSubmissionFiles,
  };
};

export default useViewStatusStepContext;
