import {
  AuthorizeAndTransmitBody,
  BinderId,
  TaxYear,
  IsSeparateYN,
  JurisdictionId,
  Period,
  ValidationBody,
  BinderFilingId,
  FilingTypeId,
  BusinessEntityId,
  SltBinderId,
  UpdateFederalReturnOptionBody,
  CheckTransmissionStatusBody,
  UpdateFederalReturnOptionResponse,
  UpdateSubstepStatusBody,
  UpdateSelectedTransmitterBody,
  SubmissionId,
  ResetReturnEFileStatusParams,
} from '../../../../common/types';
import { HttpMethods } from '../enums';
import { QueryError, QueryKeys } from '../queries';

import { useCustomMutation } from '.';

interface AuthorizeAndTransmitMutationParams {
  yearOrSubPeriodId: string;
  caseId: string;
  binderId: BinderId;
  binderFilingId: BinderFilingId;
  efSubmissionId: string;
  ttiSubmissionId: SubmissionId | null;
  taxYear: TaxYear;
  period: Period;
  businessEntityId: BusinessEntityId;
  isSeparateYN: IsSeparateYN;
  jurisdictionId: JurisdictionId;
  signatureValue: string;
  isLegalVerbiageAccepted: boolean;
  shouldIgnoreErrors: boolean;
  filingTypeId: FilingTypeId;
  businessEntityName: string;
  jurisdictionDescription: string;
  sltBinderId: SltBinderId;
}

export const useMutationAuthorizeAndTransmit = () =>
  useCustomMutation<null, QueryError, AuthorizeAndTransmitMutationParams, AuthorizeAndTransmitBody>(
    {
      handleData: ({
        yearOrSubPeriodId,
        caseId,
        binderId,
        efSubmissionId,
        ttiSubmissionId,
        binderFilingId,
        taxYear,
        period,
        businessEntityId,
        isSeparateYN,
        jurisdictionId,
        signatureValue,
        isLegalVerbiageAccepted,
        shouldIgnoreErrors,
        filingTypeId,
        businessEntityName,
        jurisdictionDescription,
        sltBinderId,
      }) => ({
        url: '/api/efile-v2-service/authorize-and-transmit',
        method: HttpMethods.POST,
        body: {
          yearOrSubPeriodId,
          caseId,
          binderId,
          efSubmissionId,
          ttiSubmissionId,
          binderFilingId,
          globalContext: {
            taxYear,
            period,
            businessEntityId,
            isSeparateYN,
            jurisdictionId,
            filingTypeId,
          },
          shouldIgnoreErrors,
          signatureValue,
          isLegalVerbiageAccepted,
          businessEntityName,
          jurisdictionDescription,
          sltBinderId,
        },
      }),
      errorMessage: 'Authorize and transmit step failed',
      parseResponseErrorMessage: true,
    },
  );

export const useMutationEFileRunValidation = () =>
  useCustomMutation<null, QueryError, ValidationBody>({
    handleData: ({
      ttiCaseId,
      yearOrSubPeriodId,
      sltBinderId,
      jurisdictionStateCode,
      jurisdictionDescription,
      globalContext,
      taxYear,
      period,
      filingTypeId,
      businessEntityId,
      jurisdictionId,
      isConsolidated,
      isSeparate,
      isSeparateYN,
      consolidationProFormaFilingGroupId,
      returnId,
      isTransmitterProviderTR,
    }) => ({
      url: isTransmitterProviderTR
        ? '/api/efile-v2-service/run-validation-for-tr'
        : '/api/efile-v2-service/run-validation-for-tti',
      method: HttpMethods.POST,
      body: {
        ttiCaseId,
        yearOrSubPeriodId,
        sltBinderId,
        jurisdictionStateCode,
        jurisdictionDescription,
        globalContext,
        taxYear,
        period,
        filingTypeId,
        businessEntityId,
        jurisdictionId,
        isConsolidated,
        isSeparate,
        isSeparateYN,
        consolidationProFormaFilingGroupId,
        returnId,
        isTransmitterProviderTR,
      },
      resetKey: QueryKeys.EFileV2.Validation,
      errorMessage: 'Validation Step failed',
    }),
    parseResponseErrorMessage: true,
  });

export const useMutationUpdateFederalReturnOption = () =>
  useCustomMutation<UpdateFederalReturnOptionResponse, QueryError, UpdateFederalReturnOptionBody>({
    handleData: ({ federalReturnOption, sltBinderId, federalSubmissionId }) => ({
      url: '/api/efile-v2/update-federal-return-option',
      method: HttpMethods.PUT,
      body: {
        federalReturnOption,
        sltBinderId,
        federalSubmissionId,
      },
    }),
    errorMessage: 'An error occurred trying to update federal return option',
    parseResponseAsJson: true,
  });

export const useMutationEFileCheckTransmissionStatus = () =>
  useCustomMutation<null, QueryError, CheckTransmissionStatusBody>({
    handleData: ({
      taxYear,
      period,
      filingTypeId,
      businessEntityId,
      jurisdictionId,
      yearOrSubPeriodId,
      ttiCaseId,
      sltBinderId,
      ttiBinderId,
      efSubmissionId,
      binderFilingId,
    }) => ({
      url: '/api/efile-v2-service/check-transmission-status',
      method: HttpMethods.POST,
      body: {
        taxYear,
        period,
        filingTypeId,
        businessEntityId,
        jurisdictionId,
        yearOrSubPeriodId,
        ttiCaseId,
        sltBinderId,
        ttiBinderId,
        efSubmissionId,
        binderFilingId,
      },
      errorMessage: 'Check transmission status failed',
      parseResponseAsJson: true,
      resetKey: QueryKeys.EFileV2.GetTransmissionStatus,
    }),
  });

export const useMutationUpdateSelectedSubstepStatus = () =>
  useCustomMutation<null, QueryError, UpdateSubstepStatusBody>({
    handleData: ({ stepId, status, returnId }) => ({
      url: '/api/admin/support/efile/update-selected-substep-status',
      method: HttpMethods.PUT,
      body: {
        stepId,
        status,
        returnId,
      },
      resetKey: QueryKeys.EFileSupport.UpdateSelectedSubstepStatus,
      errorMessage: 'An error occurred trying to update selected substep status',
    }),
  });

export const useMutationUpdateSelectedTransmitter = () =>
  useCustomMutation<null, QueryError, UpdateSelectedTransmitterBody>({
    handleData: ({ selectedTransmitter, returnId }) => ({
      url: '/api/admin/support/efile/update-selected-transmitter',
      method: HttpMethods.PUT,
      body: {
        selectedTransmitter,
        returnId,
      },
      resetKey: QueryKeys.EFileSupport.UpdateSelectedTransmitter,
      errorMessage: 'An error occurred trying to update selected transmitter',
    }),
  });

export const useMutationResetReturnEFileStatus = () =>
  useCustomMutation<null, QueryError, ResetReturnEFileStatusParams, null>({
    handleData: ({ returnId }) => ({
      url: `/api/admin/support/efile/reset-return-efile-status/${returnId}`,
      method: HttpMethods.GET,
      errorMessage: 'Reset Return EFile Status failed',
    }),
    resetKey: QueryKeys.EFileSupport.EFileBinderFilingsData,
  });
