export const preparePdfFormDataWithJsonPayload = ({
  formValues,
  pdfLibPdfFile,
  bnaPdfFile,
  connectionId,
}) => {
  const formData = new FormData();
  formData.append('values', JSON.stringify(formValues));
  formData.append('connectionId', connectionId);
  if (pdfLibPdfFile) {
    formData.append('pdfLibPdfFile', pdfLibPdfFile);
  }
  if (bnaPdfFile) {
    formData.append('bnaPdfFile', bnaPdfFile);
  }
  return formData;
};
