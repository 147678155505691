import { JobStatus } from '@common-packages/shared-constants';

import { errorNotification } from '../../shared/notification/store/actions';

import * as api from './api';
import {
  ENABLE_USER_JOBS_POLLING,
  CANCEL_USER_JOBS_POLLING,
  REFRESH_USER_JOBS,
  UPDATE_USER_JOBS_POLLING_CONF,
  RESET_USER_JOBS_POLLING_CONF,
  ENABLE_USER_JOB_POLLING,
  CANCEL_USER_JOB_POLLING,
  UPDATE_USER_JOB_POLLING_CONF,
  RESET_USER_JOB_POLLING_CONF,
  ADD_JOB_DATA_TO_STORE,
  SET_UPDATE_FILING_MEMBERS_JOB_ID,
  SET_SHOULD_CREATE_NOTIFICATION,
  CLEAR_UPDATE_FILING_MEMBERS_JOB_ID,
} from './types';

const JOB_POLLING_MAX_ERROR_COUNT = 4;

const updateDelayTime = timesErrored => {
  switch (timesErrored) {
    case 2:
      return 10000;
    case 3:
      return 20000;
    default:
      return 5000;
  }
};

const delay = (dispatch, action, milliseconds) => {
  setTimeout(() => {
    dispatch(action());
  }, milliseconds);
};

const refreshUserJobs = jobs => ({
  type: REFRESH_USER_JOBS,
  payload: jobs,
});

const enableUserJobsPolling = () => ({
  type: ENABLE_USER_JOBS_POLLING,
});

export const cancelUserJobsPolling = () => ({
  type: CANCEL_USER_JOBS_POLLING,
});

const updateUserJobsPollingConf = payload => ({
  type: UPDATE_USER_JOBS_POLLING_CONF,
  payload,
});

const resetUserJobsPollingConf = () => ({
  type: RESET_USER_JOBS_POLLING_CONF,
});

const pullUserJobs = () => async (dispatch, getState) => {
  const {
    jobs: { isPollingEnabled, pollingErrorCount },
    shared: {
      root: { clientId },
    },
  } = getState();

  if (isPollingEnabled) {
    try {
      if (clientId) {
        const jobs = await api.fetchJobs();
        dispatch(refreshUserJobs(jobs));
      }

      if (pollingErrorCount) {
        dispatch(resetUserJobsPollingConf());
      }

      delay(dispatch, pullUserJobs, 5000);
    } catch ({ isAuthorizationError }) {
      if (!isAuthorizationError) {
        console.error('An error occurred while getting background running tasks'); // eslint-disable-line no-console
      }

      if (pollingErrorCount < JOB_POLLING_MAX_ERROR_COUNT) {
        dispatch(
          updateUserJobsPollingConf({
            newCount: pollingErrorCount + 1,
          }),
        );
        delay(dispatch, pullUserJobs, updateDelayTime(pollingErrorCount));
      } else {
        dispatch(cancelUserJobsPolling());
        dispatch(resetUserJobsPollingConf());
      }
    }
  }
};

export const startUserJobsPolling = () => (dispatch, getState) => {
  const {
    jobs: { pollingErrorCount, isPollingEnabled },
  } = getState();
  if (pollingErrorCount) {
    dispatch(resetUserJobsPollingConf());
  }
  if (!isPollingEnabled) {
    dispatch(enableUserJobsPolling());
    dispatch(pullUserJobs());
  }
};

const enableUserJobPolling = payload => ({
  type: ENABLE_USER_JOB_POLLING,
  payload,
});

export const cancelUserJobPolling = payload => ({
  type: CANCEL_USER_JOB_POLLING,
  payload,
});

const updateUserJobPollingConf = payload => ({
  type: UPDATE_USER_JOB_POLLING_CONF,
  payload,
});

const resetUserJobPollingConf = payload => ({
  type: RESET_USER_JOB_POLLING_CONF,
  payload,
});

const addJobDataToStore = payload => ({
  type: ADD_JOB_DATA_TO_STORE,
  payload,
});

const pullUserJob = ({ jobType, jobId, successAction, failedAction, errorMessage }) => async (
  dispatch,
  getState,
) => {
  const {
    jobs: { pollingEnabledJobs, jobPollingErrorCount, notificationEnabledJobIds },
    shared: {
      root: { clientId },
    },
  } = getState();

  if (pollingEnabledJobs.some(enabledJobId => enabledJobId === jobId)) {
    try {
      if (clientId) {
        const jobData = await api.fetchJob({
          jobType,
          jobId,
          errorMessage,
          shouldCreateNotification: notificationEnabledJobIds.includes(jobId),
        });
        if (jobData.status === JobStatus.SUCCESS || jobData.status === JobStatus.FAILED) {
          dispatch(setShouldCreateNotification({ jobId, shouldCreateNotification: false }));
          dispatch(cancelUserJobPolling(jobId));
        }
        if (jobData.status === JobStatus.FAILED && failedAction) {
          dispatch(failedAction(jobData));
        }
        if (jobData.status === JobStatus.SUCCESS && successAction) {
          if (successAction?.current) {
            // judge if a use ref function
            dispatch(successAction.current(jobData));
          } else {
            dispatch(successAction(jobData));
          }
        }
        dispatch(addJobDataToStore(jobData));
      }

      if (jobPollingErrorCount[jobId]) {
        dispatch(resetUserJobPollingConf(jobId));
      }

      delay(
        dispatch,
        () => pullUserJob({ jobType, jobId, successAction, failedAction, errorMessage }),
        5000,
      );
    } catch ({ isAuthorizationError, ...rest }) {
      if (!isAuthorizationError) {
        console.error('An error occurred while getting background running task'); // eslint-disable-line no-console
      }

      if (jobPollingErrorCount[jobId] < JOB_POLLING_MAX_ERROR_COUNT) {
        dispatch(
          updateUserJobPollingConf({
            jobId,
            newCount: jobPollingErrorCount[jobId] + 1,
          }),
        );
        delay(
          dispatch,
          () => pullUserJob({ jobType, jobId, successAction, failedAction, errorMessage }),
          updateDelayTime(jobPollingErrorCount),
        );
      } else {
        dispatch(errorNotification(errorMessage));
        dispatch(cancelUserJobPolling(jobId));
        dispatch(resetUserJobPollingConf(jobId));
      }
    }
  }
};

export const startUserJobPolling = ({
  jobType,
  jobId,
  successAction,
  failedAction = null,
  errorMessage,
}) => (dispatch, getState) => {
  const {
    jobs: { pollingEnabledJobs, jobPollingErrorCount },
  } = getState();

  if (jobPollingErrorCount[jobId]) {
    dispatch(resetUserJobPollingConf(jobId));
  }
  if (!pollingEnabledJobs.some(enabledJobId => enabledJobId === jobId)) {
    dispatch(enableUserJobPolling(jobId));
    dispatch(pullUserJob({ jobType, jobId, successAction, failedAction, errorMessage }));
  }
};

export const setUpdateFilingMembersJobId = jobId => ({
  type: SET_UPDATE_FILING_MEMBERS_JOB_ID,
  payload: jobId,
});

export const setShouldCreateNotification = payload => ({
  type: SET_SHOULD_CREATE_NOTIFICATION,
  payload,
});

export const clearUpdateFilingMembersJobId = () => ({
  type: CLEAR_UPDATE_FILING_MEMBERS_JOB_ID,
});
