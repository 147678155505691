import { HttpMethods } from '../enums';
import { useCustomMutation } from '../mutations';

import { QueryError, QueryKeys } from './index';

interface AddBinderDefaultFormParams {
  taxYear: string;
  period: string;
}

interface DeleteBranchBody {
  connectionId: string;
}

type DeleteBranchParams = AddBinderDefaultFormParams & {
  connectionId: string;
};

interface DeleteBranchBody {
  connectionId: string;
}

export const useMutationAddMissingDefaultForm = () =>
  useCustomMutation<null, QueryError, AddBinderDefaultFormParams>({
    handleData: ({ taxYear, period }: AddBinderDefaultFormParams) => ({
      url: `/api/admin/support/utilities/binder-default-forms/${taxYear}/${period}`,
      method: HttpMethods.PUT,
    }),
    resetKey: QueryKeys.Utilities.AddBinderDefaultForms,
    successMessage: 'Successfully added default forms to empty binders',
    errorMessage: 'Error added default forms to empty binders',
  });

export const useMutationDeleteBranch = () =>
  useCustomMutation<null, QueryError, DeleteBranchParams, DeleteBranchBody>({
    handleData: ({ taxYear, period, connectionId }: DeleteBranchParams) => ({
      url: `/api/admin/support/utilities/delete-branch/${taxYear}/${period}`,
      method: HttpMethods.POST,
      body: { connectionId },
    }),
    successMessage: 'Successfully delete branch entity returns.',
    errorMessage: 'Error delete branch entity returns.',
  });
