import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import DateTime from '../../shared/displayComponents/dateTime/datetime.component';
import { jobPropTypes } from '../../jobs/propTypes';
import { JobStatus } from '../../shared/enums/job';

import SuccessUpload from './successUpload.component';
import FailedUpload from './failedUpload.component';
import ProcessingUpload from './processingUpload.component';
import WaitingUpload from './waitingUpload.component';
import styles from './uploadStatus.module.scss';

// jobId: string;
// jobType: string;
// status: number;
// progress?: number | undefined;
// startTime: string;
// endTime: string;
// result?: unknown;
// error: string | null;
const UploadStatus = ({ job, isValidationError = false, children = null }) => {
  const getStatusComponent = useCallback(() => {
    switch (job.status) {
      case JobStatus.IN_PROGRESS:
        return <ProcessingUpload />;
      case JobStatus.SUCCESS:
        return <SuccessUpload finishedOn={job.endTime} />;
      case JobStatus.FAILED:
        return <FailedUpload isValidationError={isValidationError} />;
      default:
        return <WaitingUpload />;
    }
  }, [job.status, job.endTime, isValidationError]);

  return (
    <div className={styles.uploadStatusContainer}>
      <p className={styles.lastFileUploadInfo}>
        Last file upload on: <DateTime ISOString={job.createdOn} />
      </p>
      {getStatusComponent()}
      {children}
    </div>
  );
};

UploadStatus.propTypes = {
  job: PropTypes.shape(jobPropTypes),
  isValidationError: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default UploadStatus;
